@import "../../../mixins"

.default-theme
    .manual-bank-wire-page

        .manual-bank-wire-page-title
            width: 100%
            height: 20px
            font: normal normal normal 13px/18px Open Sans
            letter-spacing: 0
            color: #575757
            margin-bottom: 10px

        .bank-items-container
            padding: 10px 30px 10px 10px

            +rmax(699)
                width: 100%
                max-width: none
                padding: 10px 5px

        .amount-wrapper
            // title mb:10 + h:20 = 30
            margin-top: 30px

        .bank-item
            background: #FFFFFF 0% 0% no-repeat padding-box
            border-radius: 4px
            border: 1px solid #D3DDE4

            &.selected
                background: #F5F5F5 0% 0% no-repeat padding-box
                border: 1px solid #FF803C

            .title
                font: normal normal normal 17px/23px Open Sans
                letter-spacing: 0
                color: #575757

            .selected-icon
                background-color: #2270a7

                .checkbox-arrow-svg
                    fill: #fff