@import "../../../@cashier-project/assets/styles/mixins"

.modal-container
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: rgba(0,0,0,.5)
    z-index: 101
    display: flex
    justify-content: center
    padding: 10px 10px 30px
    overflow-y: auto
    .close-button
        z-index: 2
    .modal
        width: 550px
        margin: 15px auto
        border-radius: 3px
        padding: 20px 20px 30px
        max-width: 90%
        background-color: #fff
        position: relative
        height: fit-content
        box-shadow: 0px 0px 10px rgba(0,0,0,.2)
        .modal-header
            display: flex
            justify-content: space-between
            width: 100%
            margin-bottom: 30px
            align-items: center
            .modal-title
                font-size: 20px
                color: #4980AA
                width: 100%
                text-align: center
                +rmax(699)
                    font-size: 18px
                +rmax(400)
                    font-size: 16px
            +rmax(699)
                margin-bottom: 15px
        .input-item
            min-height: 0px
            margin-top: 5px
            margin-bottom: 5px
        .close, .close-button
            width: 35px
            height: 35px
            position: absolute
            top: 0
            right: 0
            transform: translate(50%, -50%)
            display: flex
            justify-content: center
            align-items: center
            border-radius: 100%
            cursor: pointer
            transition: 0.25s all ease
            svg
                height: 17px
                width: 17px
            .close-button:before,
            .close-button:after
                background-color: #fff
                height: 17px

.prxqr-modal
   max-width: 620px
   height: calc(100% - 30px)
   background-color: rgba(255, 255, 255, 0.8)
