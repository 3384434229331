@import "../variables"
@import "../../../mixins"

.dark-theme
    .main-header
        border-bottom: 1px solid rgba(202, 202, 202, 0.3)
        .header-profile-container
            .header-profile-toggle
                color: $current
                border-color: $current
                svg
                    fill: $current
            .header-profile-menu
                background-color: $darkgray
        .header-logo
            .back
                color: $current
                opacity: 1
                font-size: 16px
                font-weight: normal
                text-transform: uppercase
                +rmax(699)
                    font-size: 14px
            img
                width: 40px
        .header-customer
            color: $white
            font-weight: 100
            +rmax(699)
                font-size: 14px
        .main-method
            color: $lightgray
            +rmax(699)
                font-size: 12px
        .header-username
            color: $current
            text-decoration: none
            opacity: 1
            &:hover
                text-decoration: underline
                opacity: 1
