@import "../variables"
@import "../../../mixins"
.classic
    .input-item
        .symbol-error.red-text
            padding: 0 2px
        .title
            color: $blue
            padding: 0 0 0 10px
            margin: 0 0 5px 0
            opacity: 1
        .error
            color: $red
        .input
            border: 1px solid $lightgray
            background-color: $palegray
            color: $darkgrey
            font-size: 14px
            &:focus
                border-bottom: 1px solid $blue
            &.read-only, &[disabled]
                background-color: rgba(0,0,0,0.1)
                color: rgba(34,34,34,.8)
                border: none
                user-select: none
                cursor: default
                opacity: 1
                &:focus
                    border-bottom: none
            &::placeholder
                font-size: 12px
                opacity: 0.7
                font-weight: normal
            @media screen and (max-width: 360px)
                font-size: 12px
        .input-wrap .input-icon i
            color: rgba(0,0,0,.4)
        .input-wrap .input-icon .cvv-icon:hover i
            color: rgba(0,0,0,.4)
    .focus
        .input
            border-bottom: 1px solid $blue
    .has-error
        .input
            border: 1px solid $red
        .title
            color: $red
