$current: #0CA1D7
$gray: #333333
$red: #FF0000
$green: green
$white: #FFFFFF
$lightred: #FFF6F6
$blue: #EEF5FB
$lightblue: #F7FBFF
$matwhite: #d9e1e8
$pastelwhite: #ebf0fc
$lightgray: #cacaca
$darkblue: #1A577A
$platinum: #777777
