@import "../variables"
@import "../../../mixins"

.dark-theme
	.amount-wrapper
		border-radius: 10px
		background-color: $darkgray
		padding: 5px
		.current-currency
			color: $white
		.amount-limits-component
			color: $white
		+rmax(699)
			padding: 0
			width: 100%
			min-width: auto
		.btn-wrapper
			min-height: 65px
