$white: #fff
$gray: gray
$blue: #1187F4
$dullblue: #6a83ac
$red: #dc143c
$green: green
$matgray: #5a5a5a
$dullgray: #343434
$coolgray: #333333
$softgray: #505050
$warmgray: #DDDDDD
$lightgray: #a5a5a5
$pastel: #E7E7E7
$current: #82BB41
$dirtywhite: #F4F6F6
