$blue: #4980aa
$current: #3E84BC
$white: #fff
$dark: #000
$red: rgb(207, 0, 0)
$green: green
$orange: #D20400
$yellow: #F8F300
$darkgrey: #333333
$lightgray: #dddddd
$palegray: #FAFAFA
$pastelgray: #cccccc
