//Inter - default 'Inter', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap')
//Be Vietnam Pro 'Be Vietnam Pro', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
//Bitter 'Bitter', serif;
@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
//Chakra Petch 'Chakra Petch', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap')
//Hahmlet 'Hahmlet', serif;
@import url('https://fonts.googleapis.com/css2?family=Hahmlet:wght@100;200;300;400;500;600;700;800;900&display=swap')
//Inconsolata 'Inconsolata', monospace;
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap')
//Lato 'Lato', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap')
//Lora 'Lora', serif;
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap')
//M PLUS 1 Code 'M PLUS 1 Code', monospace;
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1+Code:wght@100;200;300;400;500;600;700&display=swap')
//Montserrat 'Montserrat', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
//Noto Sans JP 'Noto Sans JP', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap')
//Noto Sans 'Noto Sans', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
//Noto Serif 'Noto Serif', serif;
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
//Nunito 'Nunito', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap')
//Open Sans 'Open Sans', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')
//Pridi 'Pridi', serif;
@import url('https://fonts.googleapis.com/css2?family=Pridi:wght@200;300;400;500;600;700&display=swap')
//PT Sans 'PT Sans', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap')
//PT Serif 'PT Serif', serif;
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap')
//Raleway 'Raleway', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
//Roboto 'Roboto', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
//Roboto Slab 'Roboto Slab', serif;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap')
//Titillium Web 'Titillium Web', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap')
//Ubuntu Mono 'Ubuntu Mono', monospace;
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap')
//Zen Maru Gothic 'Zen Maru Gothic', serif;
@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@300;400;500;700;900&display=swap')

//Source Sans Pro - not found Source Sans 3 ?

//Theme editor settings START
$border-style: var(--border_style)
$font-family: var(--font_family)
//Theme editor settings END

$font_size: var(--font_size)
$input-height: 40px

//shadows
$shadow_color: var(--shadow_color)
$shadow_xxl: 0 18px 40px 0 color-mix(in srgb, var(--shadow_color) 36%, transparent)
$shadow_xl: 0 18px 40px 0 color-mix(in srgb, var(--shadow_color) 28%, transparent)
$shadow_m: 0 12px 32px 0 color-mix(in srgb, var(--shadow_color) 24%, transparent)
$shadow_s: 0 10px 24px 0 color-mix(in srgb, var(--shadow_color) 16%, transparent)
$shadow_xs: 0 4px 24px 0 color-mix(in srgb, var(--shadow_color) 16%, transparent)
$shadow_xxs: 0 8px 32px 0 color-mix(in srgb, var(--shadow_color) 12%, transparent)

