@import "../variables"
@import "../../../mixins"

.default-theme
    .modal-container
        background-color: rgba(255,255,255,.9)
        .modal
            border-radius: 8px
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3)
            +rmax(699)
                padding: 20px 10px 30px
            .modal-header
                margin-bottom: 10px
            .credit-add-container
                +rmax(699)
                    padding: 5px 0
            .input
                color: $gray
            .close, .close-button
                background-color: $current
                &:hover
                    background-color: darken($current, 5%)
            .modal-header
                .modal-title
                    color: $current
            .save-button-container
                display: flex
                .button.save-button
                    flex-grow: initial
        &.billing-info-modal
            .input-item
                margin: 4px 10px
                padding: 0
                +rmax(320)
                    margin: 4px 0
        &.promocode-modal, &.transactions-modal
            .close-button::after, .close-button::before
                display: none
.default-theme.prxqr-modal
    max-width: 800px