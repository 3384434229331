@import "../variables"
@import "../../../mixins"

.supreme-theme
    .page-container.pay-method
        .note-error
            color: $yellow
        .amount-limits-component
            color: $white
        .promocode-title
            color: $white
            padding-top: 0
            padding-bottom: 0
        .method-wrapper
            padding: 20px 20px 30px
            border: 1px solid $dullblue
            border-radius: 20px
            margin: 0
            background-color: $dullblue
            position: relative
            z-index: 1
            +rmax(699)
                border: none
                padding: 20px 0 30px
                margin-top: 20px
                background-size: 100% 30%
            .title-container
                .title-text
                    padding: 0 15px
                    margin-bottom: 5px
                    color: $white
                    +rmax(699)
                        .logo-title-img
                            display: inline
            .add-fields-container
                &.hide
                    .pay-fields-container
                        display: none
                    .amount-wrapper
                        min-width: 260px
            .col-wrapper
                padding: 10px 0 0
                display: flex
                justify-content: space-between
                flex-wrap: wrap
                .html-add-fields
                    margin-bottom: 15px
                    margin-top: 10px
                    color: $white
                .credit-add-container
                    padding: 15px 15px 0
                    display: flex
                    flex-wrap: wrap
                    .row
                        width: 50%
                    +rmax(699)
                        .row
                            width: 100%
                .pci-container
                    padding: 0 10px 0 10px
                    width: 100%
                    #secure-exp,#secure-cvv,#secure-card-number,#secure-card-holder, .pci-card-holder-input
                        border-radius: 5px
                        +tr(0.25)
                    .pci-card-holder-input
                        padding: 5px
            &:after
                background: url("../../../../images/bg_supreme.svg") no-repeat bottom
                background-size: contain
                border-radius: 20px
                opacity: 0.46
                width: 100%
                height: 100%
                display: block
                content: ''
                position: absolute
                top: 0
                bottom: 0
                right: 0
                left: 0
                z-index: -1
        .slider-container
            width: 100%
            max-width: 100%
            padding-bottom: 0
            background-color: transparent
            z-index: 1
        .slider-item
            opacity: 1
            padding-bottom: 17px
        .current-slide
            position: relative
            .triangle
                position: absolute
                width: 15px
                height: 15px
                bottom: -0.5px
                fill: $dullblue
                path
                    stroke: $dullblue
                    fill: $dullblue
                +rmax(699)
                    display: none
        .convert-component
            flex-wrap: wrap
            .currency
                width: 100%
            +rmax(699)
                width: 100%
html[lang="he"], html[lang="ar"]
    direction: rtl
    .supreme-theme
        .page-container.pay-out-page, .page-container.pay-in-page, .page-container.pay-method
            .amount-input-container
                .current-currency
                    right: auto
                    left: 5px
        .billing-info-container
            .billing-info
                margin-bottom: 15px
    .billing-info
        [name="phone_customer"]
            direction: ltr