.error-container
    width: 100%
    height: 100%
    position: relative
    display: flex
    justify-content: center
    .error-page, .not-found-page
        height: 500px
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        font-size: 24px
        font-style: italic
        max-width: 620px
        div
            margin-top: 20px
            margin-bottom: 20px
            text-align: center
            color: #bababa
        .back
            position: absolute
            left: 0
            display: block
            top: 15px
            transform: translateY(-50%)
            font-size: 12px
            cursor: pointer
            z-index: 1
            &:hover
                text-decoration: underline
        a
            z-index: 1
        &.error-9
            .error-message
                display: block
                margin-top: 15px
                font-size: 20px
                span
                    font-weight: bold

