@import "../variables"
@import "../../../mixins"

.nova-theme
    .button
        background-color: $current
        border-radius: 30px
        border: 1px solid $current
        margin: 20px auto
        min-width: 220px
        +rmax(400)
            min-width: 150px
            padding: 10px 10px
        &:hover
            background-color: transparent
            color: $current
        &.disabled
            cursor: default
            background-color: $gray
            border-color: $gray
            &:hover
                color: $white
        &.button-outline
            background-color: transparent
            border: 1px solid $current
            color: $current
            &:hover
                background-color: $current
                color: $white
            &.disabled
                background-color: transparent
                border-color: $gray
                color: $gray
                &:hover
                    color: lighten($gray, 10%)
                    border-color: lighten($gray, 10%)
    .amount-page
        .button
            background-color: $blue
            border-color: $blue
            &:hover
                background-color: $white
                color: $blue
            &.disabled
                cursor: default
                background-color: $gray
                border-color: $gray
                &:hover
                    color: $white
    .result-container
        .button-wrapper
            display: flex
            flex-wrap: wrap
            .button
                font-size: 16px
                margin: 10px auto
