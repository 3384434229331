@import "../variables"
@import "../../../mixins"

.dark-theme
    .button
        background-color: $current
        color: $black
        border: 2px solid $current
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
        margin: 5px auto
        &:hover
            background-color: lighten($current, 10%)
            border: 2px solid lighten($current, 10%)
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2)
            transform: scale(1.03)
            opacity: 1
        &.disabled
            &:hover
                background-color: $current
                opacity: 0.7
                transform: none
        +rmax(699)
            margin: 10px auto
        &.button-outline,
        &.button-outline:hover,
        &.button-outline.disabled
            background-color: transparent
            color: $current
            border-color: $current