$white: #FFFFFF
$black: #000000
$gray: #777777
$darkgray: #333333
$red: #ff0000
$green: green
$blue: #0CA1D7
$yellow: #FEDF00
$silver: #E3E3E3
$silverdark: #909090
$ghostwhite: #F7FBFF
$darkblue: #1A577A
$dullblue: #065f95
$cadetgray: #d9e1e8
$dullwhite: #eef5fb
$aster: #efefef
$coral: #FB6E54
$dullgray: #b1b1b1
$current: #FFAF9F
$champagne: #FFF6F6
$purple: #abb7d4
$pastelgray: #cccccc
