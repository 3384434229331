@import "../../../mixins"

.classic
    .manual-bank-wire-page
        .manual-bank-wire-page-title
            width: 100%
            height: 20px

            font: normal normal normal 15px/20px Open Sans
            letter-spacing: 0
            color: #4880A9
            padding-left: 10px
            margin-bottom: 5px

        .bank-items-container
            max-width: 100%
            padding: 0
            margin-bottom: 40px

            .bank-items-list
                max-height: calc(65vh - 140px)

        .amount-wrapper
            width: 100%
            max-width: 100%
            .input-item
                flex-grow: 1

        .bank-item
            background: #FAFAFA 0% 0% no-repeat padding-box
            border: 1px solid #DDDDDD

            &.selected
                background: #3E84BC 0% 0% no-repeat padding-box
                border: 1px solid #DDDDDD

                .title
                    color: #FFFFFF

            .title
                font: normal normal normal 15px/20px Open Sans
                letter-spacing: 0
                color: #373737

            .selected-icon
                background-color: #fff

                .checkbox-arrow-svg
                    fill: #3E84BC