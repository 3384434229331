@import "../variables"
.default-theme
    .symbol-error
        display: none
    .input-item
        color: $gray
        .title
            color: $gray
        .input-wrap
            .input
                &::placeholder
                    color: $lightgray
                &.read-only, &[disabled]
                    background-color: rgba(0, 0, 0, 0.07)
                    color: rgba(34,34,34,1)
                    opacity: 0.8
                &:focus
                    background-color: $blue
        .error
            color: $red
        &.focus
            .title
                color: $current
            .input
                background-color: $blue
                border-bottom: 1px solid $current
        &.has-error
            .error
                color: $red
            .input
                border-bottom: 1px solid $red
                background-color: $lightred
            .title
                color: $red
