@import "../../mixins"

$amount-width: 260px

.col-wrapper
    .pay-fields-container
        max-width: calc(100% - #{$amount-width})
    .amount-wrapper
        min-width: 260px
        width: 260px

.col-wrapper
    display: flex
    flex-wrap: wrap
    +rmax(699)
        flex-direction: column
    .pay-fields-container
        order: 1
        padding: 10px
        width: 100%
        +rmax(699)
            width: 100%
            max-width: none
            padding: 10px 5px
    .amount-wrapper
        +rmax(699)
            padding: 10px 5px
            width: 100%
            min-width: auto

.col-wrapper
    .billing-info-container
        order: 3
    .add-fields-container
        order: 4
    .amount-wrapper
        order: 2
    .pay-fields-container
        order: 1

.method-wrapper
    .auth-form
        .col-wrapper
            .pay-fields-container
                max-width: 100%
                padding: 0
            .amount-wrapper
                width: 100%
                background-color: transparent