@import "variables"
@import "../../mixins"

.default-theme
    font-family: 'Open Sans', sans-serif
    color: $gray
    &.wrapper
        min-height: 300px
        height: auto
        +rmax(800)
            padding: 0
        .wrap
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3)
            +tr(0.25)
            max-width: 100%
            width: 800px
            min-height: auto
            border-radius: 8px
            padding: 15px 30px
            +rmax(800)
                min-height: 100vh
                box-shadow: none
                border: 0
                padding: 15px 15px 30px
            +rmax(400)
                padding: 15px 10px 20px
            +rmax(360)
                padding: 15px 8px 20px
        .slider-container
            background-color: $white
    .form-container form
      padding: 0 5px
    .title-container
        color: $darkblue
    footer
        padding: 0 30px
        font-size: 9px
        color: $platinum
        +rmax(699)
            padding: 0 5px
        .copyright
            font-size: 10px
            color: $platinum
    .custom-html
        display: flex
        width: 100%
        justify-content: center
        .pay-fields-container
            padding: 10px 20px!important
    .title-text
        text-transform: uppercase
        color: $darkblue
        letter-spacing: 0.7px
        .arrow-wrapper
            display: none
    .slick-prev:before,.slick-next:before
        color: rgba(0,0,0,.15)
    .custom-html
        .pay-fields-container
            width: 100%
            max-width: 100%
            padding: 0
    .fee-container
        margin: 3px 10px 20px
        .fee-container
            margin: 0 10px 0 10px
            +rmax(699)
                margin: 0 5px 0 5px
    .multiple-select-container
        .multiple-separator
            background-color: rgba(51, 51, 51, 0.5)
        .multiple-options
            background-color: $blue
        .multiple-dropdown-container
            .svg-dropdown
                path
                    fill: rgba(51, 51, 51, 0.5)
            &:hover
                background-color: rgba(238, 238, 238, 0.31)
    .custom-select-container
        .custom-select-dropdown-arrow-container
            .svg-dropdown
                path
                    fill: #C3C3C3
            &:hover
                background-color: rgba(238, 238, 238, 0.5)
        .custom-select-options-container
            background-color: $blue
    .pci-container
        .pci-card-holder-input
            &:focus
                background-color: $blue
                border-bottom: 1px solid $current
            &.has-error
                border-bottom: 1px solid $red
                background-color: $lightred
            &[disabled]
                color: rgba(34,34,34,1)
        .pci-card-holder
            &.focus
                .pci-card-holder-input
                    &.has-error
                        background-color: $blue
                        border-bottom: 1px solid $current
        .input-item
            .error
                color: $red
    .arrow-wrapper
        svg
            g path
                fill: #0CA1D7
