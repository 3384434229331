@import "../../../mixins"

//$amount-width: 260px
//
//.col-wrapper
//    .pay-fields-container
//        max-width: calc(100% - #{$amount-width})
//    .amount-wrapper
//        min-width: $amount-width
//        width: $amount-width

.classic
    .col-wrapper
        display: flex
        flex-wrap: wrap
        +rmax(699)
            flex-direction: column
        .pay-fields-container
            padding: 0px
            width: 100%
            max-width: none
        .pay-fields-container
            order: 1
        .amount-wrapper
            order: 2
        .checkbox-container
            order: 5
        .credit-add-container
            order: 6
