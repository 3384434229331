.main-header
    display: flex
    justify-content: space-between
    align-items: center
    height: 50px
    border-bottom: 2px solid lightgray
    .header-logo
        .back
            font-weight: bold
            cursor: pointer
            opacity: 0.8
            font-size: 14px
            padding: 5px
            display: flex
            align-items: center
            .fas.fa-chevron-left
                margin-left: 5px
                margin-right: 5px
            &:hover
                opacity: 1
        .logo-container
            display: flex
            align-items: center
        img
            width: 60px
    .main-method
        font-size: 16px
        font-weight: normal
        text-transform: uppercase
        font-weight: bold
        width: 100%
        padding: 0px 15px
        @media screen and (max-width: 400px)
            font-size: 14px
            padding: 0px 10px
    .header-customer
        white-space: nowrap
        display: flex
        align-items: center
    .header-username
        text-decoration: underline
        cursor: pointer
        padding: 5px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        display: inline-block
        max-width: 150px
        @media screen and (max-width: 320px)
            max-width: 75px
        &:hover
            text-decoration: none
            opacity: 0.8
    .header-profile-container
        position: relative
        height: 100%
        display: flex
        align-items: center
        padding: 5px
        .header-profile-toggle
            color: #0CA1D7
            cursor: pointer
            border: 1.5px solid #0CA1D7
            height: 30px
            font-size: 14px
            padding: 0 10px
            width: min-content
            border-radius: 3px
            text-transform: uppercase
            font-weight: bold
            display: flex
            justify-content: center
            align-items: center
            user-select: none
            white-space: nowrap
            &:hover
                opacity: 0.8
                transition: 0.25s all ease
            svg
                display: block
                width: 15px
                height: 15px
                margin: 0 3px 0 0
            @media screen and (max-width: 799px)
                font-size: 12px
        .header-profile-menu
            position: absolute
            z-index: 5
            background-color: #f7fbff
            padding: 10px 15px
            top: calc(100% + 5px)
            right: 0
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3)
            border-radius: 5px
            &:before
                content: ""
                position: absolute
                width: 15px
                height: 15px
                background-color: inherit
                clip-path: polygon(50% 0%, 0% 100%, 100% 100%)
                top: -10px
                right: 10px
            .header-profile-menu-item
                white-space: nowrap
                font-size: 14px
                padding: 5px 0
                cursor: pointer
                &:hover
                    text-decoration: underline
html[lang="he"], html[lang="ar"]
    .main-header
        .header-profile-container
            .header-profile-toggle svg
                margin: 0 0 0 3px

.nova-theme .wrap .main-header .header-profile-container .header-profile-toggle
    color: #1187F4
    border: 1.5px solid #1187F4

.nova-theme .wrap .main-header .header-logo .back
    display: none

.panoramic-2-0-theme .main-header
    height: 48px
