@import "../variables"

.dark-theme
    .select-options-row
        .loader-container
            .loader
                margin-top: 12px
                @media screen and (max-width: 360px)
                    margin-top: 22px
        .select-options-placeholder
            &:before
                top: 0
                padding: 5px 6px
                color: $white