$white: #FFFFFF
$black: #000000
$current: #FFBD00
$red: #FF0000
$green: green
$darkgray: #333333
$gray: #3d3d3d
$lightgray: #CACACA
$dullblack: #222222
$dullgray: #777777
$warmwhite: #ebf0fc
