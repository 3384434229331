@import "../variables"
@import "../../../mixins"

.dark-theme
    .slider-container
        width: 100%
        max-width: 215px
        margin: 0 10px 0 0
        transform: translateY(1px)
        background-color: transparent
        z-index: 1
        padding: 0
        +rmax(900)
            width: 110px
        &.compact
            max-width: 110px
    .left-gateways-list
        display: flex
        flex-wrap: wrap
        justify-content: flex-start
        align-content: flex-start
        height: 100%
        overflow: auto
        max-height: 100px
        min-height: 100%
        &::-webkit-scrollbar
            width: 5px
        &::-webkit-scrollbar-track
            background-color: $lightgray
            border-radius: 10px
        &::-webkit-scrollbar-thumb
            background-color: $white
            border-radius: 10px
        +rmax(900)
            flex-direction: column
            flex-wrap: nowrap
        .list-item
            background-color: $darkgray
            margin: 7px
            width: 90px
            height: 65px
            opacity: 0.5
            cursor: pointer
            //box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5)
            border-radius: 2px
            .img
                width: 100%
                height: 100%
                +center
                padding: 5px
                img
                    width: 100%
                    max-height: 100%
                    object-fit: contain
                img[data-svg='true']
                    background-color: transparent
            &:hover
                opacity: 1
            &.current-slide
                opacity: 1
