=center
    display: flex
    justify-content: center
    align-items: center

=justifySpaceBetween
    display: flex
    justify-content: space-between
    align-items: center

=padding
    padding: 2px 7px

=rmax($width)
    @media only screen and (max-width: $width+ "px")
        @content

=rmin($width)
    @media only screen and (min-width: $width+ "px")
        @content

=tr($tr:0.35)
    transition: $tr+s all ease

=fz($d320, $d760: $d320, $d1200: $d760, $d1900: $d1200)
    font-size: $d320+px
    +rmin(760)
        font-size: $d760+px
    +rmin(1200)
        font-size: $d1200+px
    +rmin(1900)
        font-size: $d1900+px
