.loader-container
    position: absolute
    top: 0
    bottom: 0
    right: 0
    left: 0
    background-color: rgba(255,255,255,.5)
    z-index: 100
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    padding: 15px
    &.loader-delay
        background-color: rgba(255,255,255,.9)
        .loader-delay-text
            color: #333333
            font-size: 16px
            text-align: center
            padding-top: 10px
    &.non-transparent
        background-color: rgba(255,255,255,1)
        z-index: 300
    &.initial-loading-text
        .loader-text
            animation: fadeOut 4s forwards
            -webkit-animation: fadeOut 4s forwards
            -moz-animation: fadeOut 4s forwards
            -o-animation: fadeOut 4s forwards
    @keyframes fadeOut
        from
            opacity: 1
        to
            opacity: 0
    @media screen and (max-width: 400px)
        padding: 5px
    .loader-text
        padding: 10px
        margin-bottom: 20px
        font-size: 20px
    .loader
        width: 50px
        height: 50px
        background-image: url("https://assets.praxispay.com/images/Cashier_cubes_preloader.gif")
        background-size: contain
    &.fixed
        position: fixed
        z-index: 1
        background-color: #fff
    &.index
        z-index: 2

@keyframes preloader
    to
        transform: rotate(360deg)