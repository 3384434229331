@import "../../../mixins"

.nova-theme
    .manual-bank-wire-page
        .manual-bank-wire-page-title
            width: 100%
            height: 20px
            margin-bottom: 10px
            font: normal normal normal 18px/23px Muli
            letter-spacing: 0px
            color: #575757

        .bank-items-container
            max-width: 100%
            .bank-items-list
                max-height: 60vh
                @media only screen and (max-width: 699px)
                    max-height: 50vh

        .amount-wrapper
            width: 100%
            max-width: 100%
            margin-top: 30px

        .bank-item
            background: #FFFFFF 0% 0% no-repeat padding-box
            box-shadow: 0px 1px 3px #00000029
            border-radius: 5px
            border: 1px solid transparent

            &.selected
                border: 1px solid #82BB41

            .title
                font: normal normal bold 19px/24px Muli
                letter-spacing: 0px
                color: #343434

            .selected-icon
                background-color: #82BB41

                .checkbox-arrow-svg
                    fill: #fff