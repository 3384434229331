@import "../variables"

.nova-theme
    .input-item
        .input
            color: $dullgray
            font-weight: 700
            border-bottom: 1px solid rgba(0,0,0,.25)
            &::placeholder
                font-size: 16px
                opacity: 0.7
                font-weight: normal
            &.read-only, &[disabled]
                background-color: rgba(0,0,0,.1)
                color: rgba(34,34,34,.75)
                border-bottom: none
                cursor: default
        .title
            font-size: 11px
            color: $softgray
            text-transform: uppercase
        &.has-error
            border-bottom: $red
            .title
                color: $red
            .input
                border-bottom: 1px solid $red
            .input-wrap
                &:before
                    content: "\f071"
                    position: absolute
                    bottom: 10px
                    font-family: 'Font Awesome 5 Free'
                    font-weight: 900
                    right: 30px
                    z-index: 1
                    color: $red
    .credit-add-container
        .input-wrap
            position: relative
            &:after
                content: "\f002"
                position: absolute
                left: 2px
                top: 50%
                transform: translateY(-50%) rotate(45deg)
                font-family: 'Font Awesome 5 Free'
                font-weight: 900
                font-size: 17px
                color: $blue
            .input
                padding-left: 30px
            .title
                padding-left: 30px
