.panoramic-2-0-theme
    .footer
        display: flex
        justify-content: center
        flex-direction: column
        gap: 4px
        color: var(--neutral_active)
        font-size: 0.75rem
        padding: 0
        margin: 0
        @media (max-width: 480px)
            font-size: 0.625rem
        .footer-content
            display: flex
            flex-direction: column
            gap: 24px
            text-align: center
            font-size: inherit
            color: inherit
            @media (max-width: 480px)
                text-align: left
            .custom-footer-links
                display: flex
                justify-content: center
                align-items: center
                height: 30px
                gap: 24px
                flex-wrap: wrap
                @media(max-width: 600px)
                    gap: 16px
                @media (max-width: 480px)
                    justify-content: flex-start
                a
                    color: inherit
                    font-size: inherit
                    &:hover
                        color: var(--primary_active)
        .copyright
            text-align: center
            margin: 0
            padding: 0
            font-size: inherit
            font-family: inherit
            gap: 4px
            color: inherit
            @media (max-width: 480px)
                text-align: left

