@import "../variables"
@import "../../../mixins"

.dark-theme
    .result-container
        .title-text
            .error-message
                color: $white
                background-color: $red
                border-top: none
                +center
                padding: 10px
        .table-container
            padding: 10px
            .content-box
                &.info
                    .table
                        border-spacing: 0
                        tr
                            td
                                border: none
                                color: $white
                                padding: 7px 5px
                                &:first-child
                                    text-align: right
                                    white-space: nowrap
                                    color: $white
                                &:nth-child(2)
                                    text-align: left
                                    color: $lightgray
                                    &.declined, &.error, &.cancelled
                                        span
                                            +center
                                            padding: 3px
                                            text-transform: uppercase
                                            color: $white
                                            background-color: $red
            .image.content-box
                width: 30%
                +rmax(699)
                    width: 70%
            .gradient
                display: block
                position: absolute
                left: 0
                right: 0
                bottom: 0
                height: 195px
                background-image: none
        .button-wrapper
            .button
                min-width: 260px
            .back-to-site-button
                background-color: transparent
                border: 2px solid $current
                color: $current
        .transaction-status-wrapper
            td.declined, td.error, td.cancelled
                span
                    text-transform: uppercase
                    color: $red
            td.approved
                span
                    text-transform: uppercase
                    color: $green
            padding: 10px 0
            span
                display: inline
            .result-charge-amount
                color: $white
                span
                    color: $lightgray
    .gradient
        display: block
        position: absolute
        left: 0
        right: 0
        bottom: 0
        height: 195px
        background-image: linear-gradient(180deg, $white 0%, $warmwhite 100%)
    .result-redirect
        color: $lightgray
    .auto-redirect-button
        color: $lightgray
    .manual-bank-result-container
        .manual-bank-default-content
            .manual-bank-details-table
                background-color: $darkgray
                border-radius: 6px
