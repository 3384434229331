.transactions-pending-table
    & + .transactions-status-container
        margin-top: 10px
        .delete-transaction-error
            font-size: 14px
            color: #FF0000
            margin-top: 10px
            opacity: 0.7
        .success-transaction
            font-size: 14px
            color: green
            margin-top: 20px
            opacity: 1
            display: flex
            align-items: center
            svg
                flex-shrink: 0
    .not-transactions-found
        padding: 20px
        color: rgba(0,0,0,0.5)
        font-size: 16px
    table
        width: 100%
        thead
            tr
                &:hover
                    background-color: inherit
                th
                    border: 1px solid rgba(0, 0, 0, 0.1)
                    background-color: transparent
                    font-size: 14px
                    padding: 5px 10px
        tr
            &:hover
                background-color: rgba(0, 0, 0, 0.02)
        th
            font-weight: normal
            background-color: rgba(0, 0, 0, 0.1)
            padding: 5px 2px
            font-size: 12px
            &.transactions-page-title
                background-color: transparent
            @media screen and (max-width: 360px)
                font-size: 10px
        td
            padding: 7px 10px
            font-size: 12px
            text-align: center
            @media screen and (max-width: 900px)
                padding: 7px 4px
            @media screen and (max-width: 370px)
                padding: 7px 4px
                text-align: center
            @media screen and (max-width: 360px)
                font-size: 10px
            .button.deleteTransactionBtn
                min-width: 50px
                margin: 0 auto
                font-size: 10px
                height: 25px
                padding: 0 5px
                box-shadow: none
                max-width: 100px
                color: #FF0000
                background-color: transparent
                border: 1px solid #FF0000
                letter-spacing: normal
                @media screen and (max-width: 360px)
                    font-size: 10px
                    padding: 0 1px
                    min-width: 35px
                    height: 20px
                &:hover
                    background-color: #FF0000
                    color: #fff
                    svg
                        fill: #fff
                svg
                    width: 13px
                    height: 13px
                    margin: 0 3px 0 0
