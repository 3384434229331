@import "../variables"

.nova-theme
    footer
        color: $matgray
        font-size: 12px
        line-height: 20px
        border-top: 1px solid $warmgray
        padding-top: 20px
        margin-top: 0
        .content
            margin-bottom: 20px
            padding: 0 20px
        .copyright
            text-align: center
            color: $dullblue
