.transactions-modal
  .modal
    width: 700px
    height: fit-content
    .transactions-pending-table
      max-height: 270px
      min-height: 200px
      overflow-x: auto
      &::-webkit-scrollbar
        width: 5px
        height: 5px
      &::-webkit-scrollbar-track
        background-color: #eee
        border-radius: 10px
      &::-webkit-scrollbar-thumb
        background-color: #999
        border-radius: 10px


.transactions-modal-container
  .transactions-title.button
    font-size: 12px
    height: 30px
    min-width: auto
    box-shadow: none
    font-weight: normal

.transactions_pending
  display: flex
  flex-direction: column
  margin-bottom: 20px
  .row
    display: flex
    justify-content: space-between
    .col
      border: 1px solid #eee
      flex: 1
      display: flex
      justify-content: center
      padding: 10px
      min-height: 50px
      align-items: center
      a
        width: 100%
        min-width: 100%
        height: 100%
        margin: 0
        padding: 5px
        font-size: 14px
  .transactions_pending_tbody
    font-size: 12px
.transactions-title-container
  display: inline-block
  cursor: pointer
