@import "../variables"
@import "../../../mixins"

.nova-theme
    .gateways-container
        position: relative
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        padding-top: 20px
        .gateway-item
            cursor: pointer
            width: 48%
            min-height: 168px
            padding: 0
            position: relative
            border-radius: 15px
            border: 1px solid $blue
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16)
            margin: 0 0 20px 0
            text-align: center
            background-color: $white
            display: flex
            flex-wrap: wrap
            .title
                height: 44px
                order: 0
                width: 100%
                font-size: 16px
                +center
                background-color: $blue
                line-height: 1.25
                color: $white
                border-radius: 13px 13px 0 0
                margin-top: 0
                +rmax(699)
                    font-size: 14px
                +rmax(400)
                    font-size: 12px
            .img
                padding: 14px
                order: 1
                width: 100%
                img
                    max-width: 100%
                    max-height: 90px
                    width: 100px
                    margin: auto
                    object-fit: contain
