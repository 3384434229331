@import "../variables"

.dark-theme
    .input-item
        color: $darkgray
        .title
            color: $white
            border-radius: 3px
            opacity: 1
            transform: translate(6px, 10px)
            z-index: 2
            background-color: $darkgray
            display: inline-block
            position: relative
            padding: 0 3px
        .title-none
            background-color: transparent
        .input-wrap
            .input-icon
                border-radius: 3px
                padding: 1px
                img
                    background-color: $white
                    padding: 2px
                    border-radius: 2px
            .input
                border: 1px solid $white
                border-radius: 4px
                background-color: $darkgray
                color: $white
                &::placeholder
                    color: rgba(205, 205, 205, 1)
                &.read-only, &[disabled]
                    background-color: $darkgray
                    opacity: 0.6
                    border: 1px solid $dullgray
                &:focus
                    background-color: $darkgray
                    border: 1px solid $current
        .error
            color: $white
            background-color: $red
            border-radius: 3px
            display: flex
            align-items: center
            padding: 0 3px
        &.focus
            .title
                color: $current
                opacity: 1
            .input
                border: 1px solid $current
                background-color: $darkgray
                &::placeholder
                    opacity: 0
        &.has-error
            .error
                color: $white
                background-color: $red
            .input
                border: 1px solid $red
                color: $red
                background-color: $darkgray
            .title
                color: $white
                background-color: $red
