@import "../variables"
@import "../../../mixins"

.classic
    .modal-container
        background-color: rgba(255,255,255,.9)
        &.transactions-modal
            .modal-header
                margin-bottom: 10px
        &.profile-modal
            .modal-header
                margin-left: 7px
                margin-bottom: 10px
        &.promocode-modal
            .save-button-container
                .button
                    max-width: 200px
        .close, .close-button
            background-color: $current
            &:hover
                background-color: darken($current, 5%)
.classic.prxqr-modal
    .prxqr-modal-content
        border-radius: 0
    .prxqr-primary-color
        color: $current
    .prxqr-primary-color svg path
        stroke: $current


