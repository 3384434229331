@import "variables"
@import "../../mixins"

.nova-theme
    font-family: 'Muli', sans-serif

    .slider-amount-wrapper
        .total-input
            padding: 5px

        .cash-slider-currency
            padding: 5px

        .current-currency
            white-space: nowrap

        .cash-slider-top
            padding-right: 50px

        .input-item.currency .input-wrap
            display: flex

            label
                display: block
                width: 100%
                z-index: 1

            .arrow-wrapper
                display: block
                position: absolute
                right: 7px
                top: 11px


    .input
        padding: 5px

    .title-text
        span
            display: block

            &.no-deposit-free
                content: 'No deposit fee. no withdrawal fee.'
                display: block
                color: $lightgray
                font-size: 13px
                padding: 4px 0

    &.wrapper
        .wrap
            border: 1px solid transparent
            background-color: $dirtywhite
            padding: 0
            overflow: hidden

    .page-container
        padding: 0 20px 0 20px

        > .title-text
            display: none

        +rmax(699)
            padding: 0 10px 0 10px

    .result-container
        > .title-text
            display: block

    .page-container.amount-page
        > .form
            margin: 20px 0 0 0
            padding: 0 20px 30px 20px
            +rmax(400)
                padding: 0 0 30px 0

    .row
        width: 100%
        display: flex
        justify-content: center
        align-items: flex-end
        flex-wrap: wrap

        &.row-col
            flex-wrap: nowrap

    .result-container
        color: $matgray
        font-size: 14px
        line-height: 20px
        padding-top: 15px
        display: flex
        flex-wrap: wrap
        flex-direction: column

        .transaction-status-wrapper
            span
                display: block

            td.declined, td.error, td.cancelled
                span
                    text-transform: uppercase
                    color: $red

            td.approved
                span
                    text-transform: uppercase
                    color: $current

        .table
            display: block
            width: 100%

        .title-text
            div
                margin-bottom: 10px

    .page-container
        .back
            margin-bottom: 10px
            cursor: pointer
            display: block

            .arrow-wrapper
                position: absolute
                top: 3px
                left: 0
                transform: rotate(90deg)

                svg g path
                    fill: #000

    .pci-container
        width: 100%
        margin-bottom: 0
        padding: 0

        .input-item
            margin-bottom: 0

            .pci-card-holder-input
                background-color: transparent
                color: #343434
                font-weight: 700
                border-bottom: 1px solid rgba(0, 0, 0, 0.25)

                &[disabled]
                    background-color: rgba(0, 0, 0, 0.1)
                    color: rgba(34, 34, 34, 0.75)
                    border-bottom: none
                    cursor: default

                &:focus
                    background-color: transparent

                &.has-error
                    border-bottom: 1px solid #dc143c

            .pci-card-holder
                &.focus
                    .pci-card-holder-input
                        &.has-error
                            background-color: transparent
                            border-bottom: 1px solid rgba(0, 0, 0, 0.25)

            .error
                color: #dc143c

        #cards-list
            .multiple-options
                border-radius: 0
                box-shadow: none
                border: 1px solid #a2a2a2

    .fee-container
        width: 100%

    .confirm-container .confirm-window
        .confirmation-required
            background-color: $current

    .manual-bank-result-container
        .manual-bank-default-content
            .bank-details-title
                +rmax(699)
                    padding: 50px 0 10px

            h3, h4
                max-width: 520px

            .manual-bank-details-table
                border-collapse: collapse
                background-color: transparent
                max-width: 520px
                margin: 0 auto
                +rmax(699)
                    padding: 0

                td
                    border: 1px solid rgba(165, 165, 165, 0.51)

    .custom-select-container
        .custom-select-dropdown-arrow-container
            .svg-dropdown
                path
                    fill: #1187F4

        .custom-select-options-container
            background-color: #fff
            border: 1px solid rgb(162 162 162)
            box-shadow: none
            padding: 0

            .custom-select-option
                font-size: 16px
                transition: all 0.3s ease
                background-color: transparent
                color: #000
                padding: 5px 10px 5px 15px

                &:hover
                    background-color: #1E90FF
                    color: #ffffff

                &.active
                    background-color: lighten(#1E90FF, 10%)
                    color: #ffffff
