@import "../variables"

.nova-theme
    .checkbox-accept-container
        margin-bottom: 0
        .checkbox-container
            margin-bottom: 0
        .checkbox-error
            color: $red
            font-style: normal
            text-transform: uppercase
            font-size: 11px
    .credit-add-container
        padding: 10px 0
    .numpad-wrap
        margin: 0
        width: 100%
        .numpad-container
            transform: translate(0%, calc(100% - 10px))
            left: 0
html[lang="he"], html[lang="ar"]
    .nova-theme
        .input-item
            &.has-error
                .input-wrap
                    &:before
                        right: auto
                        left: 20px

        select.input
            background: transparent url("../../../../images/arrowDown.svg")  1% 70% no-repeat
            background-size: 15px
html[lang="he"], html[lang="ar"]
    .nova-theme
        .input-item
            &.cardNumberBlock, &.cardCvvBlock, &.cardExpBlock
                &.has-error
                    .input-wrap
                        &:before
                            right: 10px
                            left: auto
html[lang="he"], html[lang="ar"]
    .nova-theme .slider-amount-wrapper .input-item.currency .input-wrap .arrow-wrapper
        left: 0
        right: auto

