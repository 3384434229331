@import "../../../@cashier-project/assets/styles/mixins"

html[lang="he"], html[lang="ar"]
    select
        background: transparent url("../../../@cashier-project/assets/images/arrowDown.svg")  2% 65% no-repeat
        background-size: 13px
    .limits-container
        text-align: right
    .input-item select.input
        padding-right: 5px
        padding-left: 25px
    .checkbox-container
        .checkbox-label
            margin: 0 0 0 10px
select
    -webkit-appearance: none
    background: transparent url("../../../@cashier-project/assets/images/arrowDown.svg")  95% 57% no-repeat
    background-size: 15px
select::-ms-expand
    display: none
input, select
    outline: none
.row
    display: flex
    &>div
        min-width: 50%
.input-item
    padding: 2px 0
    position: relative
    min-height: 75px
    width: 100%
    &.focus
        .title
            opacity: 1
        .input
            &::placeholder
                opacity: 0
    .input-wrap
        width: 100%
        margin: 2px 0
        position: relative
        .input-icon
            width: 25px
            height: 15px
            position: absolute
            right: 5px
            top: 50%
            transform: translateY(-50%)
            display: flex
            justify-content: center
            img
                max-width: 100%
                max-height: 100%
                object-fit: contain
            i
                color: #CACACA
                display: block
            .cvv-icon
                cursor: pointer
                display: inline
                i
                    transition: 0.15s all ease
                &:hover
                    i
                        color: darken(#CACACA, 10%)
    .input
        width: 100%
        border: none
        border-bottom: solid 1px rgba(165, 165, 165, 0.3)
        padding: 5px
        height: 35px
        background-color: transparent
        font-family: inherit
        font-size: 16px
        transition: 0.15s all ease
        &.read-only, &[disabled]
            background-color: rgba(0, 0, 0, 0.07)
            opacity: 0.8
        &:focus, &:hover, &:active
            outline: none
            text-decoration: none
        @media screen and (max-width: 360px)
            font-size: 14px
        @media screen and (max-width: 320px)
            font-size: 12px
        &::placeholder
            font-size: 14px
            transition: 0.15s all ease
            @media screen and (max-width: 320px)
                font-size: 12px
    .title
        padding: 0 2px
        font-size: 12px
        line-height: 1
        white-space: nowrap
        overflow: hidden
        max-width: 90%
        text-overflow: ellipsis
        height: 12px
        opacity: 0.8
        transition: 0.15s all ease
        @media screen and (max-width: 360px)
            font-size: 10px
    .error
        width: 100%
        font-size: 11px
        opacity: 0.8
        transition: 0.15s all ease
        line-height: 1
        font-style: italic
        height: 22px
        &.hide
            opacity: 0
            overflow: hidden
    select.input
        padding-right: 25px
    .pci-iframe-container
        width: 100%
        height: 100%

input
    .input
        .pci-input[name=cvv]
            padding-right: 40px