@import "../variables"
@import "../../../mixins"

.classic
    .main-header
        +justifySpaceBetween
        height: 50px
        border-bottom: 2px solid $blue
        padding: 2px 5px 7px
        .header-profile-container .header-profile-toggle
            color: $blue
            border-color: $blue
            svg
                fill: $blue
        .header-logo
            .back
                font-weight: bold
                color: $blue
                padding: 10px 0
                display: flex
                align-items: center
                .arrow-wrapper
                    transform: rotate(90deg)
                    margin: 5px 0 5px 0
                    height: 12px
                    svg g path
                        fill: #4980aa
                img
                    width: 12px
                    fill: red
            .logo-container
                +center
            img
                width: 50px
                +rmax(400)
                    width: 40px
        .main-method
            color: $blue
            font-size: 16px
            text-transform: uppercase
            font-weight: bold
            width: 100%
            padding: 0 15px
            +rmax(400)
                font-size: 14px
                padding: 0 10px
        .header-customer
            white-space: nowrap
            +rmax(400)
                font-size: 14px
        .header-username
            text-decoration: underline
            color: $darkgrey
            cursor: pointer
            padding: 5px
            &:hover
                color: lighten($dark, 40%)
    .title-container
        text-align: center
        position: relative
        .logo-title
            +center
        .title-text
            font-size: 16px
            .logo-title-img
                display: inline
.main-header.header-virtual_terminal
    background-color: $orange
    padding: 10px
    position: relative
    color: $white
    border-bottom: none
    .header-logo
        img
            width: 100%
            height: 100%
            display: block
        .back
            color: $white
            opacity: 1
            .arrow-wrapper svg g path
                fill: $white
            span
                color: $white
        .main-method
            color: $white
    .main-method
        color: $white
        padding: 0 5px
    .header-customer
        z-index: 1
        font-size: 14px
        display: flex
        align-items: center
        .title-wrapper
            display: flex
            align-items: center
            color: $white
        .header-username
            color: $white
            &:after
                display: none
        i
            padding: 0 5px 0 0
            color: $yellow
        +rmax(400)
            font-size: 12px
