@import "../../../mixins"

.dark-theme
    .billing-info-container
        .billing-info
            border-radius: 3px
            color: #000
            background-color: #fff
            border: 1px solid #fff
        .billing-info-error
            color: #FF0000
