@import "../../../mixins"

.dark-theme
    .manual-bank-wire-page
        .manual-bank-wire-page-title
            width: 100%
            height: 20px
            font: normal normal normal 15px/20px Open Sans
            letter-spacing: 0
            color: #FFFFFF
            margin-top: 2px
            margin-bottom: 3px

        .bank-items-container
            padding: 5px 20px 5px 5px
            +rmax(699)
                width: 100%
                max-width: none
                padding: 0

        .amount-wrapper
            padding-top: 7px
            +rmax(699)
                padding-top: 5px
            .input
                height: 50px

        .bank-item
            background-color: #333333
            border: 1px solid #F9F9F9
            border-radius: 4px

            &.selected
                border: 1px solid #FFBD00

            .title
                font: normal normal normal 17px/23px Open Sans
                letter-spacing: 0px
                color: #FFFFFF

            .selected-icon
                background-color: #FFBD00

                .checkbox-arrow-svg
                    fill: #000000