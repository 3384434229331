@import "../variables"
@import "../../../mixins"

.default-theme
    .gateways-container
        .gateway-item
            width: 150px
            .title
                padding: 12px 0
                font-size: 12px
                font-weight: 400
                text-align: center
                margin: 0
            .img
                background-color: $white
                border-radius: 5px
                height: 90px
                +tr(0.25)
                box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2)
                img
                    +tr(0.25)
            &:hover
                .img
                    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.4)
