@import "../variables"

.classic
    .checkbox-container
        font-size: 16px
        margin-bottom: 0
        .checkbox-label
            border-color: $current
        .checkbox-input
            &:checked ~ .checkbox-label
                background-color: $current
    .checkbox-accept-container
        margin-bottom: 0
        .checkbox-error
            margin-top: 0
