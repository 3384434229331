@import "../variables"
@import "../../../mixins"

.classic
    .result-container
        min-height: 500px
        +center
        flex-direction: column
        .table-container
            position: relative
            padding: 20px 5px
            display: flex
            .content-box
                margin: 10px 15px
                z-index: 1
            .image.content-box
                +center
                width: 40%
                z-index: 1
                .status-image
                    img
                        width: 100%
        .button-wrapper
            +center
            flex-direction: column
        .transaction-status-wrapper
            display: flex
            flex-direction: column
            justify-content: center
            padding: 10px
            td.declined, td.error, td.cancelled
                span
                    text-transform: uppercase
                    color: $red

            td.approved
                span
                    text-transform: uppercase
                    color: $current
            span
                display: block
                text-align: left
            .result-charge-amount
                padding: 10px
                color: rgba(0, 0, 0, 0.6)
                text-align: center
                font-size: 14px
                line-height: 1.6
                font-style: italic
                span
                    display: inline
                    white-space: nowrap
                    font-weight: bold
        .title-text
            font-size: 18px
            .status
                font-size: 16px
                line-height: 1.4
                text-transform: none
                margin-top: 5px
                margin-bottom: 5px
            .error-message
                font-family: Courier New
                border-top: 1px solid $pastelgray
                padding-top: 10px
                font-size: 12px
                margin-top: 9px
    .result-redirect
        color: rgba(0,0,0,.5)
        padding: 20px 15px
