@import "../variables"
@import "../../../mixins"

.nova-theme
    .modal-container
        background-color: rgba(255,255,255,.9)
        .modal
            .modal-header
                margin-bottom: 10px
                .modal-title
                    color: $blue
                    +rmax(699)
                        margin-bottom: 15px
                .button
                    max-width: 220px
            .button
                margin: 10px auto
        &.profile-modal
            .modal-header
                margin-left: 7px
    .close, .close-button
        background-color: $blue
        &:hover
            background-color: darken($blue, 5%)
.nova-theme.prxqr-modal
    .prxqr-modal-content
        border-radius: 0
    .prxqr-primary-color
        color: $blue
    .prxqr-primary-color svg path
        stroke: $blue
