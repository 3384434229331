.skeleton
    width: 100%
    max-width: 875px
    padding: 15px 0
    margin: 0 auto
    .skeleton-container
        display: flex
        flex-direction: row
        gap: 10px

    .skeleton-item
        margin-bottom: 10px
        border-radius: 4px
        animation: skeletonShimmer 1.5s infinite

    .skeleton-wrap
        display: flex
        flex-wrap: wrap

        @media (max-width: 450px)
            width: 100%

@keyframes skeletonShimmer
    0%
        background-color: #f3f4f6
    50%
        background-color: #e8eaee
    100%
        background-color: #f3f4f6
