@import "../variables"

.supreme-theme
    .window-redirect-container
        background: url("../../../../images/bg_supreme.png") $dullblue no-repeat bottom
        background-size: contain
        .content-text,
        .title-text
            color: $white
        .button
            height: 45px
            font-size: 16px