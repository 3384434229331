@import "variables"
@import "../../mixins"

.supreme-theme
    font-family: 'Open Sans', sans-serif
    color: $darkgray
    .loader-container
        .loader
            background-image: url("https://assets.praxispay.com/images/Cashier_cubes_preloader.gif")
    &.wrapper
        min-height: 300px
        height: auto
        +rmax(699)
            padding: 0
            background-image: url("../../../images/bg_light_supreme.svg")
            background-size: contain
        .wrap
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3)
            +tr(0.25)
            max-width: 100%
            width: 710px
            min-height: auto
            border-radius: 8px
            padding: 15px 30px
            +rmax(800)
                min-height: 100vh
                box-shadow: none
                border: 0
                padding: 15px 15px 30px
                background: transparent
            +rmax(400)
                padding: 15px 10px 20px
            +rmax(360)
                padding: 15px 8px 20px
        .slider-container
            background-color: $aster
    .checkbox-accept-container
        .checkbox-error
            color: $yellow
    .form-container form
        padding: 0 5px
    .title-container
        color: $darkblue
    .slider-container .slick-slider .slider-item .img
        border: 1px solid $cadetgray
    .slider-container .slick-slider .slider-item.current-slide .img
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5)
        border: none
        border-radius: 5px
    footer
        padding: 0 30px
        margin: 40px 0 0 0
        font-size: 9px
        color: $gray
        +rmax(699)
            padding: 0 5px
        .copyright
            font-size: 10px
            color: $gray
    .custom-html
        display: flex
        width: 100%
        justify-content: center
        .pay-fields-container
            padding: 10px 20px!important
    .title-text
        text-transform: uppercase
        color: $darkgray
        letter-spacing: 0.7px
        font-weight: 400
        .arrow-wrapper
            display: none
    .slick-prev:before,.slick-next:before
        color: rgba(0,0,0,.15)
    .custom-html
        .pay-fields-container
            display: flex
            flex-direction: column
            width: 100%
            max-width: 100%
            padding: 0
            color: $white
    .transactions-page
        .transactions-page-title
            color: $white
        .new-transaction
            +rmax(699)
                font-size: 16px
                height: 45px
        .table
            tr
                td, th
                    color: $white
                    border: 1px solid rgba(238, 238, 238, 0.51)
                    +rmax(699)
                        font-size: 10px
                    a
                        +rmax(699)
                            font-size: 10px
    .transaction-status-wrapper
        td.declined, td.error, td.cancelled
            span
                text-transform: uppercase
                color: $red
        td.approved
            span
                text-transform: uppercase
                color: $green
        .fee-container
            margin: 5px 10px 5px 10px
    .multiple-select-container
        .multiple-separator
            background-color: rgba(51, 51, 51, 0.5)
        .multiple-options
            background-color: $dullwhite
        .multiple-dropdown-container
            .svg-dropdown
                path
                    fill: rgba(51, 51, 51, 0.5)
            &:hover
                background-color: rgba(238, 238, 238, 0.5)
    .custom-select-container
        .custom-select-dropdown-arrow-container
            .svg-dropdown
                path
                    fill: rgba(51, 51, 51, 0.5)
            &:hover
                background-color: rgba(238, 238, 238, 0.5)
        .custom-select-options-container
            top: 40px
            background-color: $dullwhite
            border-radius: 5px

    .pci-container
        .pci-card-holder-input
            &:focus
                background-color: $dullwhite
            &[disabled]
                background-color: $silver
                color: rgba(0, 0, 0, 0.5)
                opacity: 1
            &.has-error
                border: 1px solid $yellow
                background-color: $champagne
        .pci-card-holder
            &.focus
                .pci-card-holder-input
                    &.has-error
                        background-color: $dullwhite
                        border: none
        .input-item
            .error
                color: $yellow
    .arrow-wrapper
        svg
            g path
                fill: #FB6E54
