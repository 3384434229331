@import "../variables"
@import "../../../mixins"

.default-theme
    .main-header
        border-bottom: 2px solid rgba(0,0,0, 0.05)
        .header-logo
            .back
                color: $current
                font-size: 16px
                font-weight: normal
                text-transform: uppercase
                +rmax(699)
                    font-size: 14px
            img
                width: 40px
        .header-customer
            color: $platinum
            +rmax(699)
                font-size: 14px
        .main-method
            color: $current
            +rmax(699)
                font-size: 12px
        .header-username
            color: $current
            text-decoration: none
            opacity: 1
            &:hover
                text-decoration: underline
                opacity: 1
