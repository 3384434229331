@import "variables"

.classic
    color: $darkgrey

    .note-error
        color: $red

    .confirm-container .confirm-window .confirmation-required
        background-color: $blue

    .title-text
        color: $blue

        .arrow-wrapper
            display: none

    .pci-container
        width: 100%
        padding: 0

        form
            padding: 15px 0

            #secure-exp, #secure-cvv, #secure-card-number, #secure-card-holder, .pci-card-holder-input
                background-color: $palegray
                box-sizing: border-box
                outline: none
                height: 35px

            .pci-card-holder-input
                padding: 1px 8px 0 8px
                border: 1px solid $lightgray

                &[disabled]
                    background-color: rgba(0, 0, 0, .1)
                    color: rgba(34, 34, 34, .8)
                    border: none
                    -webkit-user-select: none
                    -ms-user-select: none
                    user-select: none
                    cursor: default
                    opacity: 1

                &:focus
                    border-bottom: 1px solid #4980aa
                    background-color: #FAFAFA

                &.has-error
                    border: 1px solid #cf0000

            .pci-card-holder
                &.focus
                    .pci-card-holder-input
                        &.has-error
                            border-bottom: 1px solid #4980aa
                            background-color: #FAFAFA

            .input-item
                .error
                    color: #cf0000

        #cards-list
            .multiple-options
                border-radius: 0
                box-shadow: none
                border: 1px solid $lightgray

    .custom-select-container
        .custom-select-dropdown-arrow-container
            .svg-dropdown
                path
                    fill: rgba(51, 51, 51, 0.5)

        .custom-select-options-container
            background-color: $white
            border-radius: 0
            box-shadow: none
            border: 1px solid $lightgray