.confirm-container
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    justify-content: center
    align-items: center
    z-index: 1
    .confirm-window
        background-color: white
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3)
        width: 300px
        min-height: 200px
        max-width: 100%
        max-height: 300px
        padding: 40px 10px 10px
        border-radius: 8px
        display: flex
        flex-direction: column
        justify-content: space-around
        position: relative
        overflow: hidden
        .confirmation-required
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 40px
            text-align: center
            padding: 5px
            font-size: 14px
            background-color: #0CA1D7
            display: flex
            justify-content: center
            align-items: center
            color: white
            text-transform: uppercase
        .confirm-title
            font-size: 16px
            opacity: 0.7
            line-height: 1.5
            text-align: center
            margin-bottom: 10px
        .confirm-btn-wrapper
            display: flex
            justify-content: space-around
            .button
                min-width: 100px
                height: 30px
                margin: 0 5px
                font-size: 14px
                box-shadow: none
