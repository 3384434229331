@import "../variables"

.nova-theme
    .checkbox-container
        font-size: 16px
        .checkbox-label
            border-color: $current
        .checkbox-input
            &:checked ~ .checkbox-label
                background-color: $current
        .checkbox-label
            height: 25px
            width: 25px
            min-width: 25px
            min-height: 25px
