@import "../../../@cashier-project/assets/styles/mixins"

.button, a.button
    margin: 0 auto
    padding: 0 10px
    text-decoration: none
    text-transform: uppercase
    font-weight: bold
    font-family: inherit
    font-size: 16px
    cursor: pointer
    outline: none
    color: #fff
    background-color: #0CA1D7
    border: none
    min-width: 185px
    max-width: 100%
    width: fit-content
    height: 40px
    letter-spacing: 1px
    white-space: nowrap
    +center
    text-align: center
    overflow: hidden
    +tr(0.25)
    border-radius: 3px
    opacity: 1
    &:hover
        opacity: 0.8
    &:focus
        outline: none
    &.disabled
        opacity: 0.7
        &:hover
            opacity: 0.5
    &.button-outline
        background-color: transparent
        border: 2px solid #0CA1D7
        color: #0CA1D7