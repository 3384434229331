@import "../variables"
@import "../../../mixins"

.default-theme
    .result-container
        .title-text
            .error-message
                border-top: none
        .table-container
            padding: 10px
            .content-box
                &.info
                    .table
                        tr
                            td, th
                                color: $gray
                                border: 1px solid rgba(165, 165, 165, 0.51)
                                +rmax(699)
                                    font-size: 12px
                                a
                                    +rmax(699)
                                        font-size: 10px
                    .transaction-status-wrapper
                        td.declined, td.error, td.cancelled
                            span
                                text-transform: uppercase
                                color: $red
                        td.approved
                            span
                                text-transform: uppercase
                                color: green
            .image.content-box
                width: 30%
                +rmax(699)
                    width: 60%
            .gradient
                display: block
                position: absolute
                left: 0
                right: 0
                bottom: 0
                height: 195px
                background-image: linear-gradient(180deg, $white 0%, $pastelwhite 100%)
        .button-wrapper
            .button
                min-width: 260px
            .back-to-site-button
                background-color: transparent
                border: 2px solid $current
                color: $current
        .transaction-status-wrapper
            span
                display: inline
            +rmax(699)
                padding: 0
    .gradient
        display: block
        position: absolute
        left: 0
        right: 0
        bottom: 0
        height: 195px
        background-image: linear-gradient(180deg, $white 0%, $pastelwhite 100%)

    .manual-bank-result-container
        .manual-bank-default-content
            .bank-details-title
                text-transform: uppercase
