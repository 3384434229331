@import "../variables"
@import "../../../mixins"

.default-theme
    .page-container.pay-method
        .method-wrapper
            padding: 20px
            border: 1px solid $matwhite
            border-radius: 20px
            margin: 0
            +rmax(699)
                border: none
                padding: 0
                margin-top: 20px
            .title-container
                .title-text
                    padding: 0 10px
                    margin-bottom: 10px
                    +rmax(699)
                        .logo-title-img
                            display: inline
            .col-wrapper
                .credit-add-container
                    padding: 15px 0
                    display: flex
                    flex-wrap: wrap
                    .row
                        width: 50%
                    +rmax(699)
                        .row
                            width: 100%
        .slider-container
            width: 100%
            max-width: 100%
            transform: translateY(1px)
            padding-bottom: 0
            background-color: transparent
            z-index: 1
        .slider-item
            padding-bottom: 17px
            opacity: 1
            .img
                border-radius: 2px
                border: 1px solid $matwhite
        .current-slide
            position: relative
            .img
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5)
                border-radius: 5px
                border: none!important
            .triangle
                position: absolute
                width: 15px
                height: 15px
                bottom: -0.5px
                +rmax(699)
                    display: none
        .convert-component
            flex-wrap: wrap
            .currency
                width: 100%
            +rmax(699)
                width: 100%
                .currency
                    width: 100%
html[lang="he"], html[lang="ar"]
    direction: rtl
    .default-theme
        .page-container.pay-out-page, .page-container.pay-in-page, .page-container.pay-method
            .amount-input-container
                .current-currency
                    right: auto
                    left: 5px
