@import "../variables"
@import "../../../mixins"

.classic
    .error.hide
        opacity: 0
    .pay-method
        .numpad-wrap
            margin: 0
            width: 100%
            .numpad-container
                transform: translate(-50%, calc(100% - 20px))
        .card-types
            font-size: 12px
            min-width: 100%
            padding: 0 10px 5px
            display: flex
            justify-content: flex-end
            align-items: center
            color: $darkgrey
            .img-box
                width: 17px
                height: 17px
                +center
                margin-right: 5px
                margin-left: 5px
                img
                    opacity: 0.5
                    width: 100%
                    max-height: 80%
                    filter: grayscale(80%)
                    &.active
                        opacity: 1
                        filter: grayscale(0)
                        text-decoration: underline
        .with-templates
            .templates
                width: 200px
                min-width: 30%
        .credit-add-container
            width: 100%
            padding: 20px 0
