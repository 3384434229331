@import "../variables"
@import "../../../mixins"

.supreme-theme
    .result-container
        min-height: 500px
        +center
        flex-direction: column
        .table-container
            padding: 20px 5px
            +center
            +rmax(600)
                flex-direction: column
                .info.content-box
                    order: 1
                .image.content-box
                    order: 2
            .gradient
                display: none
            .content-box
                margin: 10px 15px
                z-index: 1
                +rmax(320)
                    margin: 10px 0
            .image.content-box
                +center
                width: 35%
                z-index: 1
                .status-image
                    img
                        width: 100%
                +rmax(699)
                    width: 50%
        .button-wrapper
            .button
                margin: 10px 0
                height: 40px
                font-size: 16px
            .result-charge-amount
                padding: 10px
                color: rgba(0, 0, 0, 0.6)
                text-align: center
                font-size: 14px
                line-height: 1.6
                font-style: italic
                span
                    display: inline
                    white-space: nowrap
                    font-weight: bold
        .title-text
            font-size: 18px
            .status
                font-size: 16px
                line-height: 1.4
                text-transform: none
                margin-top: 5px
                margin-bottom: 5px
            .error-message
                font-family: Courier New
                border-top: 1px solid $pastelgray
                padding-top: 10px
                font-size: 12px
                margin-top: 9px
    .result-redirect
        color: rgba(0,0,0,.5)
        padding: 20px 15px
    .back-site-container
        display: flex
        padding: 20px 10px

    .auto-redirect-button
        color: rgba(0,0,0,.5)
        padding: 5px 15px
        display: flex
        flex-direction: column
        .button.disabled
            margin-top: 5px
            cursor: default

    .manual-bank-result-container
        .manual-bank-default-content
            .bank-details-title
                text-transform: uppercase
