@import "../variables"

.dark-theme
    .checkbox-accept-container
        .checkbox-container
            .checkbox-label
                border-color: $current
                .checkbox-arrow-svg
                    fill: $black
            .checkbox-input
                &:checked ~ .checkbox-label
                    background-color: $current
        .checkbox-error
            color: $white
            background-color: $red
            padding: 5px
            border-radius: 3px
