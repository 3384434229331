@import "../../../mixins"

$amount-width: 240px

.dark-theme
    .col-wrapper
        .pay-fields-container
            max-width: calc(100% - #{$amount-width})
            padding: 5px
        .amount-wrapper
            min-width: $amount-width
            width: $amount-width
            +rmax(900)
                width: 220px
                min-width: 220px
            +rmax(699)
                width: 100%
                min-width: auto
    .col-wrapper
        display: flex
        flex-wrap: wrap
        +rmax(699)
            flex-direction: column
        .pay-fields-container
            padding: 5px
            padding-right: 20px
            width: 100%
            +rmax(699)
                width: 100%
                max-width: none
                padding: 0