@import "../../../mixins"

.classic
    .gateways-container
        .gateway-item
            +tr(0.25)
            .img
                +tr(0.25)
            .title
                opacity: 0.7
                +tr(0.25)
                +rmax(400)
                    font-size: 14px
            &:hover
                .img
                    box-shadow: 0 0 7px 0 rgba(0,0,0,0.5)
                    transform: scale(1.02)
                .title
                    opacity: 1
